<script>

	export let navList = false;
	export let navItems = walkNavItems(Array.from(navList));
	export let depth = 0;

	console.log(navItems);

	const mainNavigation = document.getElementById('mainNavigation');

	function walkNavItems(navArray) {
		return navArray.map((item) => {
			let submenu = (item.classList.contains('menu-item-has-children')) ? Array.from(item.children[1].children) : null;
			let target_attr = item.children[0].target ? item.children[0].target : '';
			return {
				liClass: item.classList,
				title: item.children[0].innerText,
				href: item.children[0].href,
        target: target_attr,
				submenu: (submenu !== null) ? walkNavItems(submenu) : false
			}
		});
	}

	const showSubmenu = (e) => {
		let submenu = e.currentTarget.children[0].nextElementSibling;
		let submenus = Array.from(document.getElementsByClassName('submenu'));
		if (submenu !== null) {
			submenus.forEach((item) => item.classList.remove('show', 'hide'));
			submenu.classList.add('show');
		}
	}

	const hideSubmenu = (e) => {
		let submenu = e.currentTarget.children[0].nextElementSibling;
		if (submenu !== null) {
			submenu.classList.add('hide');
			submenu.classList.remove('show');
			setTimeout(() => submenu.classList.remove('hide'), 500);
		}
	}

// {#if navItem.target !== false}{navItem.target}{/if}
	//mainNavigation.remove();

</script>

<nav class="main-navigation desktop-navigation">
  <ul class="level-{depth}">
    {#each navItems as navItem}
      <li class={navItem.liClass} on:mouseenter={showSubmenu} on:mouseleave={hideSubmenu}>
        <a href="{navItem.href}" class="menu-item-link" target="{navItem.target}">{navItem.title}</a>
        {#if Array.isArray(navItem.submenu)}
          <div class="submenu">
            <svelte:self navItems={navItem.submenu} depth={depth + 1}/>
          </div>
        {/if}
      </li>
    {/each}
  </ul>
</nav>
