<script>
	import { isMobile } from './utilities/store';
  import TailwindCSS from "./TailwindCSS.svelte";
  import Header from "./components/Header.svelte";
  import NavToggle from "./components/NavToggle.svelte";
  import MainContainer from "./components/MainContainer.svelte";
  import NavBar from "./components/NavBar.svelte";
	import CrForm from "./components/CrForm.svelte";
  import WP_Block_Columns from "./components/content/WP_Block_Columns.svelte";


	let innerWidth = window.innerWidth;
	const handleResizeWidth = () => {
		innerWidth = window.innerWidth;
	}

	$: {
		$isMobile = (innerWidth < 960);
	}

	// Header Component
	if (document.querySelector('#header')) {
		let header = document.getElementById('header');
		new Header({
			target: header
		});
	}

	// Main Container
	if (document.querySelector('#mainContainer')) {
		let mainContainer = document.getElementById('mainContainer');
		new MainContainer({
			target: mainContainer,
			props: {
				target: mainContainer
			}
		});
	}

	// NavToggle Component
	if (document.querySelector('#navToggle')) {
		let navToggle = document.getElementById('navToggle');
		new NavToggle({
			target: navToggle
		});
	}

	// Navbar Component
	if (document.querySelector('#navbar')) {
		let navbar = document.getElementById('navbar');
		new NavBar({
			target: navbar,
			props: {
				target: navbar
			}
		});
	}

	// Cr Form Component
	if (document.querySelector('#cr_subcribe_form')) {
		let crform = document.getElementById('cr_subcribe_form');
		new CrForm({
			target: crform,
			props: {
				target: crform
			}
		});
	}


	// WP Block Columns Component
	if (document.querySelectorAll('.wp-block-columns').length !== 0) {
		Array.from(document.querySelectorAll('.wp-block-columns')).forEach((node) => new WP_Block_Columns({
			target: node,
			props: {
				target: node
			}
		}));
	}


</script>
<svelte:window on:resize={handleResizeWidth} bind:innerWidth={innerWidth} />
<TailwindCSS/>
